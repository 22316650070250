import type { GlobalSearchRequest } from "~/types/search-types/GlobalSearchRequest";
import type { InsightSearchRequest } from "~/types/search-types/InsightSearchRequest";
import type { LocationSearchRequest } from "~/types/search-types/LocationSearchRequest";
import type { OpenPositionSearchRequest } from "~/types/search-types/OpenPositionSearchRequest";
import type { PeopleSearchRequest } from "~/types/search-types/PeopleSearchRequest";
import type { VenturesInsightSearchRequest } from "~/types/search-types/VenturesInsightSearchRequest";



export async function fetchOpenPositionSearch(requestData:OpenPositionSearchRequest)  {
    var data = await $fetch('/api/search/open-position-search', {
        method: 'POST',
        body: JSON.stringify(requestData),
        });
    return data
}
    
export async function fetchInsightSearch(requestData:InsightSearchRequest){
    var data = await $fetch('/api/search/insight-search', {
        method: 'POST',
        body: JSON.stringify(requestData),
        });
    return data
}

export async function fetchVenturesInsightSearch(requestData:VenturesInsightSearchRequest){
    var data = await $fetch('/api/search/ventures-insight-search', {
        method: 'POST',
        body: JSON.stringify(requestData),
        });
    return data
}

export async function fetchLocationsSearch(requestData:LocationSearchRequest){
    var data = await $fetch('/api/search/location-search', {
        method: 'POST',
        body: JSON.stringify(requestData),
        });
    return data
}

export async function fetchPeopleSearch(requestData:PeopleSearchRequest){
    var data = await $fetch('/api/search/people-search', {
        method: 'POST',
        body: JSON.stringify(requestData),
        });
    return data
}

export async function fetchGlobalSearch(requestData:GlobalSearchRequest){
    var data = await $fetch('/api/search/global-search', {
        method: 'POST',
        body: JSON.stringify(requestData),
        });
    return data
}
